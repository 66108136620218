import { ApiMenu } from '@repo/api/model';
import { applyFilter } from "./api/apply-filter";
import { Log, simpleAsciiFold } from '@repo/utils';

export const applyNameFilter = (menu: ApiMenu, nameFilter: string): ApiMenu => {
    if (!nameFilter) {
        return menu;
    }
    const foldedNameFilter = simpleAsciiFold(nameFilter).toLowerCase();
    return applyFilter(menu, (item) => {
        if(item.publishName && item.publishName.length) {
            if (item.publishName.find(name => simpleAsciiFold(name.value).toLowerCase().includes(foldedNameFilter))) {
                return true;
            }
        }
        if (item.name && item.name.length) {
            if (item.name.find(name => simpleAsciiFold(name.value).toLowerCase().includes(foldedNameFilter))) {
                return true;
            }
        }

        return false;
    });
};
