import { z } from 'zod';
import { TranslatableSchema } from './translatable-schema';
import { WineCategorySchema } from './wine-category';
import { FoodCategorySchema } from './food-category';
import { CountrySchema } from './country';
import { RegionSchema } from './region';
import { QuantityUnitSchema } from './quantity-unit';
import { ImageSchema } from './image';
import { GrapeSchema } from './grape';
import { MillesimeSchema } from './millesime';
import { StyleSchema } from './style';
import { AromaSchema } from './aroma';
import { LabelSchema } from './label';
import { AllergenSchema } from './allergen';
import { DomainSchema } from './domain';

export enum ApiMenuItemType {
    SUB_ITEM = 'sub-item',
    ITEM = 'item'
}

export const MenuItemTypeSchema = z.enum(['item', 'sub-item']);

export enum ApiMenuItemDisplayType {
    WINE = 'WINE',
    FOOD = 'FOOD',
    COCKTAIL = 'COCKTAIL',
    MISC = 'MISC',
    DRINK = 'DRINK',
    SECTION = 'SECTION',
    SUB_SECTION = 'SUB_SECTION'
}
export const MenuItemDisplayTypeSchema = z.enum(['WINE', 'FOOD', 'COCKTAIL', 'MISC', 'DRINK', 'SECTION', 'SUB_SECTION']);

export const MenuItemIngredientSchema = z.object({
    name: z.array(TranslatableSchema),
    weight: z.number().optional(),
    unit: z.string().optional()
});

export type ApiMenuItemIngredient = z.infer<typeof MenuItemIngredientSchema>;

export const BaseMenuItemSchema = z.object({
    id: z.string(),
    type: MenuItemTypeSchema,
    displayType: MenuItemDisplayTypeSchema,
    restaurantName: z.string().optional(),
    restaurantId: z.string().optional(),
    group: z.string().optional(),
    isVisible: z.boolean().optional(),
    visibility: z.object({
        name: z.string(),
        id: z.string(),
    }).optional(),
    portions: z.number().optional(),
    ingredients: z.array(MenuItemIngredientSchema).optional(),
    nutritionalDeclaration: z.array(TranslatableSchema).optional(),
    name: z.array(TranslatableSchema),
    publishName: z.array(TranslatableSchema).optional(),
    nameId: z.string(),
    shortDescription: z.array(TranslatableSchema).optional(),
    longDescription: z.array(TranslatableSchema).optional(),
    wineCategory: WineCategorySchema.optional(),
    foodCategoryAssociation: z.array(FoodCategorySchema).optional(),
    country: CountrySchema.optional(),
    region: RegionSchema.optional(),
    millesime: MillesimeSchema.optional(),
    style: z.array(StyleSchema).optional(),
    aroma: z.array(AromaSchema).optional(),
    label: z.array(LabelSchema).optional(),
    alcoholContent: z.number().optional(),
    servingTemperature: z.string().optional(),
    price: z.coerce.number().optional(),
    isVisibleForPrice: z.boolean().optional(),
    priceOffer: z.coerce.number().optional(),
    pricePerGlass: z.coerce.number().optional(),
    isVisibleForPricePerGlass: z.boolean().optional(),
    pricePerGlassOffer: z.coerce.number().optional(),
    quantity: z.coerce.number().optional(),
    quantityPerGlass: z.coerce.number().optional(),
    quantityUnit: QuantityUnitSchema,
    isFrozen: z.boolean().optional(),
    isFeatured: z.boolean().optional(),
    isNew: z.boolean().optional(),
    grapes: z.array(GrapeSchema).optional(),
    image: ImageSchema.optional(),
    additives: z.array(z.object({
        code: z.string()
    })).optional(),
    energyValueKJ: z.number().optional(),
    energyValueCal: z.number().optional(),
    fatGrams: z.number().optional(),
    saturatedFatGrams: z.number().optional(),
    glucoseGrams: z.number().optional(),
    sugarGrams: z.number().optional(),
    proteinGrams: z.number().optional(),
    saltGrams: z.number().optional(),
    allergens: z.array(AllergenSchema).optional(),
    domain: DomainSchema.optional()
});

export const MenuItemSchema = BaseMenuItemSchema.extend({
    items: z.lazy(() => z.array(BaseMenuItemSchema)).optional(),
});

export type ApiMenuItem = z.infer<typeof MenuItemSchema>;
