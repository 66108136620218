import { FilterSchema } from './filter-schema';
import { FilterType } from './filter-type';

export const areFiltersApplied = (filterSchema: FilterSchema) => {
    const isNameFilterApplied = filterSchema[FilterType.Name] !== ''
        && filterSchema[FilterType.Name] !== undefined
        && filterSchema[FilterType.Name] !== null;

    const isByGlassFilterApplied = filterSchema[FilterType.ByGlass] !== undefined
        && filterSchema[FilterType.ByGlass] !== null
        && filterSchema[FilterType.ByGlass];

    const isFoodCategoryFilterApplied = Array.isArray(filterSchema[FilterType.FoodCategory])
        && filterSchema[FilterType.FoodCategory]!.length > 0;

    const isWineStyleFilterApplied = Array.isArray(filterSchema[FilterType.WineStyle])
        && filterSchema[FilterType.WineStyle]!.length > 0;

    const isWineCategoryFilterApplied = Array.isArray(filterSchema[FilterType.WineType])
        && filterSchema[FilterType.WineType]!.length > 0;

    const isGrapeFilterApplied = Array.isArray(filterSchema[FilterType.WineGrapes])
        && filterSchema[FilterType.WineGrapes]!.length > 0;

    const isRegionFilterApplied = Array.isArray(filterSchema[FilterType.Region].countries) && filterSchema[FilterType.Region].countries.length > 0
        || Array.isArray(filterSchema[FilterType.Region].regions) && filterSchema[FilterType.Region].regions.length > 0;

    const isMillesimeFilterApplied = Array.isArray(filterSchema[FilterType.Millesime])
        && filterSchema[FilterType.Millesime]!.length > 0;

    const isWineAromaFilterApplied = Array.isArray(filterSchema[FilterType.WineAroma])
        && filterSchema[FilterType.WineAroma]!.length > 0;

    const isLabelFilterApplied = Array.isArray(filterSchema[FilterType.Label])
        && filterSchema[FilterType.Label]!.length > 0;

    const isPriceFilterApplied = filterSchema[FilterType.Price] !== undefined && filterSchema[FilterType.Price] !== -1;

    return isNameFilterApplied
        || filterSchema[FilterType.Featured]
        || filterSchema[FilterType.Offer]
        || filterSchema[FilterType.New]
        || isFoodCategoryFilterApplied
        || isByGlassFilterApplied
        || isWineStyleFilterApplied
        || isWineCategoryFilterApplied
        || isGrapeFilterApplied
        || isRegionFilterApplied
        || isMillesimeFilterApplied
        || isWineAromaFilterApplied
        || isLabelFilterApplied
        || isPriceFilterApplied;
};
