export enum FilterType {
    ByGlass = 'byGlass',
    Name = 'name',
    Featured = 'featured',
    New = 'new',
    Offer = 'offer',
    WineType = 'wineCategory',
    WineGrapes = 'grapes',
    Region = 'region',
    Millesime = 'millesime',
    FoodCategory = 'foodCategory',
    Price = 'price',
    WineStyle = 'wineStyle',
    WineAroma = 'aroma',
    Label = 'label'
}
