'use client';
import { createSlice, PayloadAction, Slice, } from '@reduxjs/toolkit';
import { ApiMenu, ApiMenuItem, ApiMenuItemType, ApiMenuTemplate } from '@repo/api/model';
import { GetMenuTemplateResponse } from '../api/restaurants/[restaurantSlug]/menu-template/[templateId]/route-types';
import { GetRestaurantMenuResponse } from '../api/restaurants/[restaurantSlug]/menu/[menuId]/route-types';
import { GetRestaurantResponse } from '../api/restaurants/[restaurantSlug]/route-types';
import { restaurantMenuApi } from './api/restaurant-menu-api';
import { restaurantApi } from './api/restautant-api';
import { AppState } from './app-state';
import { AppStateReducers } from './app-state-reducers';
import { applyBottleFilter } from './apply-bottle-filter';
import { applyFeaturedFilter } from './apply-featured-filter';
import { applyFoodCategoryFilters } from './apply-food-category-filters';
import { applyLabelFilter } from './apply-label-filter';
import { applyMillesimeFilter } from './apply-millesime-filter';
import { applyNameFilter } from './apply-name-filter';
import { applyPriceFilter } from './apply-price-filter';
import { applyRegionFilter } from './apply-region-filter';
import { applyWineAromaFilter } from './apply-wine-aroma-filter';
import { applyWineCategoryFilters } from './apply-wine-category-filters';
import { applyWineGrapesFilters } from './apply-wine-grapes-filters';
import { applyWineStyleFilters } from './apply-wine-style-filters';
import { areFiltersApplied } from './are-filters-applied';
import { FilterType } from './filter-type';
import { getPriceRanges } from './get-price-ranges';
import { getUniqueAromas } from './get-unique-aromas';
import { getUniqueCountries } from './get-unique-countries';
import { getUniqueFoodCategoryAssociations } from './get-unique-food-category-associations';
import { getUniqueGrapes } from './get-unique-grapes';
import { getUniqueLabels } from './get-unique-labels';
import { getUniqueMillesime } from './get-unique-millesime';
import { getUniqueRegions } from './get-unique-regions';
import { getUniqueWineCategories } from './get-unique-wine-categories';
import { getUniqueWineStyles } from './get-unique-wine-styles';
import { initialState } from './initial-state';
import { applyFilter } from './api/apply-filter';
import { applyNewFilter } from './apply-new-filter';
import { applyOfferFilter } from './apply-offer-filter';
import exp from 'constants';

export const appStateSlice: Slice<AppState, AppStateReducers, 'appState'> = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        setIsMenuLoading: (state, action: PayloadAction<boolean>) => {
            state.isMenuLoading = action.payload;
        },
        clearMenuTemplate: (state) => {
            state.menuTemplate = undefined;
        },
        clearMenu: (state) => {
            state.menu = undefined;
        },
        clearRestaurant: (state) => {
            state.restaurant = undefined;
        },
        setIsMobileFilterOpen: (state, action: PayloadAction<boolean>) => {
            state.isMobileFilterPanelOpen = action.payload;
        },
        setAppliedFilters: (state, action: PayloadAction<AppState['appliedFilters']>) => {
            state.appliedFilters = action.payload;
            applyFilters(state);
        },
        setLang: (state, action: PayloadAction<string>) => {
            state.lang = action.payload.toLowerCase();
            applyFilters(state);
        },
        setSelectedMenuId: (state, action: PayloadAction<string>) => {
            state.selectedMenuId = action.payload;
        },
        setSelectedItemId: (state, action: PayloadAction<string>) => {
            state.selectedItemId = action.payload;
        },
        setOpenedSections: (state, action: PayloadAction<string[]>) => {
            state.openedSections = action.payload;
        },
        setMenu: (state, action: PayloadAction<ApiMenu>) => {
            state.menu = action.payload;
            applyFilters(state);
        },
        setMenuTemplate: (state, action: PayloadAction<ApiMenuTemplate>) => {
            state.menuTemplate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(restaurantApi.endpoints.getRestaurant.matchFulfilled, (state, action: PayloadAction<GetRestaurantResponse>) => {
            state.restaurant = action.payload.data;
        });
        builder.addMatcher(restaurantMenuApi.endpoints.getMenu.matchFulfilled, (state, action: PayloadAction<GetRestaurantMenuResponse>) => {
            if (!action.payload.data) {
                return;
            }

            state.menu = action.payload.data;
            const allItems: ApiMenuItem[] = getAllItems(action.payload.data);
            const originalUniqueItems = getUniqueItems(allItems);
            const priceRanges = getPriceRanges(originalUniqueItems);

            state.minPrice = priceRanges.minPrice;
            state.maxPrice = priceRanges.maxPrice;
            state.minPricePerGlass = priceRanges.minPricePerGlass;
            state.maxPricePerGlass = priceRanges.maxPricePerGlass;
            applyFilters(state);
        });
        builder.addMatcher(restaurantMenuApi.endpoints.getMenuTemplate.matchFulfilled, (state, action: PayloadAction<GetMenuTemplateResponse>) => {
            state.menuTemplate = action.payload.data;
            applyFilters(state);
        });
    }
});

const applyFilters = (state: AppState): void => {
    if (!state.menu) return;

    let filteredMenu = applyFilter(state.menu, (item) => {
        return !!item.isVisible;
    });

    state.areItemsAvailableByGlass = state.menu.sections?.some((section) => section.items?.some((item) => item.isVisibleForPricePerGlass || item.items?.some((subItem) => subItem.isVisibleForPricePerGlass)));

    filteredMenu = applyFeaturedFilter(filteredMenu, state.appliedFilters[FilterType.Featured]);
    filteredMenu = applyNewFilter(filteredMenu, state.appliedFilters[FilterType.New]);
    filteredMenu = applyOfferFilter(filteredMenu, state.appliedFilters[FilterType.Offer]);
    filteredMenu = applyBottleFilter(filteredMenu, state.appliedFilters[FilterType.ByGlass]);
    filteredMenu = applyNameFilter(filteredMenu, state.appliedFilters[FilterType.Name]);
    filteredMenu = applyFoodCategoryFilters(filteredMenu, state.appliedFilters[FilterType.FoodCategory]);
    filteredMenu = applyWineStyleFilters(filteredMenu, state.appliedFilters[FilterType.WineStyle]);
    filteredMenu = applyWineCategoryFilters(filteredMenu, state.appliedFilters[FilterType.WineType]);
    filteredMenu = applyWineGrapesFilters(filteredMenu, state.appliedFilters[FilterType.WineGrapes]);
    filteredMenu = applyRegionFilter(filteredMenu, state.appliedFilters[FilterType.Region]);
    filteredMenu = applyMillesimeFilter(filteredMenu, state.appliedFilters[FilterType.Millesime]);
    filteredMenu = applyWineAromaFilter(filteredMenu, state.appliedFilters[FilterType.WineAroma]);
    filteredMenu = applyLabelFilter(filteredMenu, state.appliedFilters[FilterType.Label]);
    filteredMenu = applyPriceFilter(filteredMenu, state.appliedFilters[FilterType.Price]);

    const allItems: ApiMenuItem[] = getAllItems(filteredMenu);
    const uniqueItems: ApiMenuItem[] = getUniqueItems(allItems);

    state.filteredMenu = filteredMenu;
    state.uniqueMenuItems = uniqueItems;
    state.uniqueFoodCategoryAssociations = getUniqueFoodCategoryAssociations(uniqueItems);
    state.uniqueWineCategories = getUniqueWineCategories(uniqueItems);
    state.uniqueWineStyles = getUniqueWineStyles(uniqueItems);
    state.uniqueGrapes = getUniqueGrapes(uniqueItems);
    state.uniqueCountries = getUniqueCountries(uniqueItems);
    state.uniqueRegions = getUniqueRegions(uniqueItems);
    state.uniqueMillesime = getUniqueMillesime(uniqueItems);
    state.uniqueAromas = getUniqueAromas(uniqueItems);
    state.uniqueLabels = getUniqueLabels(uniqueItems);
    state.areFiltersApplied = areFiltersApplied(state.appliedFilters);
};

export const getUniqueItems = (items: ApiMenuItem[]): ApiMenuItem[] => {
    if (!items) {
        return [];
    }
    const uniqueItems: ApiMenuItem[] = [];
    for (const item of items) {
        if (item.items) {
            for (const subItem of item.items) {
                uniqueItems.push(subItem);
            }
            continue;
        }
        uniqueItems.push(item);
    }
    return uniqueItems;
};

export const getAllItems = (menu: ApiMenu): ApiMenuItem[] => {
    if (!menu.sections) {
        return [];
    }
    const allItems: ApiMenuItem[] = [];
    for (const section of menu.sections) {
        if (section.items) {
            allItems.push(...section.items);
        }
    }
    return allItems;
};

export const {
    clearMenuTemplate,
    clearMenu,
    clearRestaurant,
    setLang,
    setAppliedFilters,
    setSelectedMenuId,
    setSelectedItemId,
    setIsMobileFilterOpen,
    setOpenedSections,
    setIsMenuLoading,
    setMenu,
    setMenuTemplate
} = appStateSlice.actions;

export const appSliceReducer = appStateSlice.reducer;
