import { FilterType } from './filter-type';

import { AppState, BackofficeAppState } from './app-state';
import { ApiMenuItemDisplayType } from '@repo/api/model';


export const getDefaultAppliedFilters = () => ({
    [FilterType.ByGlass]: false,
    [FilterType.Name]: '',
    [FilterType.Featured]: false,
    [FilterType.New]: false,
    [FilterType.Offer]: false,
    [FilterType.WineType]: [],
    [FilterType.WineGrapes]: [],
    [FilterType.Region]: {
        countries: [],
        regions: []
    },
    [FilterType.Millesime]: [],
    [FilterType.FoodCategory]: [],
    // @ts-ignore
    [FilterType.Price]: -1,
    [FilterType.WineStyle]: [],
    [FilterType.WineAroma]: [],
    [FilterType.Label]: []
});

export const initialState: AppState = {
    // @ts-ignore
    filteredMenu: {

    },
    areItemsAvailableByGlass: false,
    // @ts-ignore
    menuTemplate: {},
    openedSections: [],
    // @ts-ignore
    restaurant: {},
    isMenuLoading: true,
    selectedItemId: '',
    selectedMenuId: '',
    lang: 'en',
    isTransitioning: false,
    uniqueMenuItems: [],
    currency: 'RON',
    itemsMap: {},
    appliedFilters: getDefaultAppliedFilters(),
    uniqueFoodCategoryAssociations: [],
    // @ts-ignore
    menu: {},
    uniqueWineCategories: [],
    maxPrice: 0,
    minPrice: 0,
    maxPricePerGlass: 0,
    minPricePerGlass: 0,
    uniqueGrapes: [],
    uniqueCountries: [],
    uniqueRegions: [],
    uniqueMillesime: [],
    uniqueWineStyles: [],
    uniqueAromas: [],
    uniqueLabels: [],
    areFiltersApplied: false,
    isMobileFilterPanelOpen: false
};

export const initialBackofficeState: BackofficeAppState = {
    productEditorSelectorOptions: {
        wineCategory: [],
        foodCategoryAssociation: [],
        style: [],
        country: [],
        region: [],
        millesime: [],
        aroma: [],
        label: [],
        grapes: [],
        allergens: []
    },
    itemEditFlag: '',
    groupItemEditFlag: '',
    globalItemEditFlag: '',
    // @ts-ignore
    selectedRestaurant: {},
    // @ts-ignore
    selectedTab: {},
    // @ts-ignore
    selectedMenu: {},
    // @ts-ignore
    selectedEditableProduct: {},
    selectedProductDisplayType: ApiMenuItemDisplayType.FOOD,
    breadcrumbs: [],
    menuItemSearchQuery: '',
    productsSearchQuery: '',
    restaurantItems: [],
    groupItems: [],
    globalItems: [],
    addSectionDialogState: {
        isOpen: false
    },
    addSubsectionDialogState: {
        isOpen: false,
        sectionId: ''
    },
    editSectionDialogState: {
        isOpen: false,
        section: {
            id: '',
            name: [],
            description: [],
        }
    },
    editSubsectionDialogState: {
        isOpen: false,
        sectionId: '',
        subsection: {
            id: '',
            name: [],
            shortDescription: [],
        }
    },
    deleteSectionDialogState: {
        isOpen: false,
        sectionId: ''
    },
    deleteSubsectionDialogState: {
        isOpen: false,
        sectionId: '',
        subsectionId: ''
    },
    publishMenuItemDialogState: {
        isOpen: false,
        subsectionId: '',
        sectionId: ''
    },
    itemsPublishedIn: {}
}
