import { ApiMenu } from "@repo/api/model";
import { applyFilter } from "./api/apply-filter";

export const applyNewFilter = (menu: ApiMenu, isNewFilterApplied: boolean): ApiMenu => {
    if (!isNewFilterApplied) return menu;

    return applyFilter(menu, (item) => {
        return !!item.isNew;
    });
};
