

import { ApiMenu } from "@repo/api/model";
import { applyFilter } from "./api/apply-filter";

export const applyOfferFilter = (menu: ApiMenu, isOfferFilterApplied: boolean): ApiMenu => {
    if (!isOfferFilterApplied) return menu;

    return applyFilter(menu, (item) => {
        return (
            (!!item.isVisibleForPrice && !!item.price && !!item.priceOffer) ||
            (!!item.isVisibleForPricePerGlass && !!item.pricePerGlass && !!item.pricePerGlassOffer)
        );
    });
};
