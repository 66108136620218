import { ApiCountry, ApiMenuItem } from '@repo/api/model';

export const getUniqueCountries = (menuItems: ApiMenuItem[]) => {
    const uniqueCountries: ApiCountry[] = [];
    for (let i = 0; i < menuItems.length; i++) {
        const item = menuItems[i]!;
        const { country } = item;
        if (!country) {
            continue;
        }
        if (!uniqueCountries.find(i => i.name === country.name)) {
            if (country && country.name) {
                uniqueCountries.push(country);
            }
        }
    }
    return uniqueCountries
        .sort((a, b) => a.name.localeCompare(b.name));
};
